*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
    -webkit-user-select: none;
    -webkit-user-select: none;
    -webkit-touch-callout: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

a:hover {
    color: #2F2F2F !important;
}

html, body{
    height: 100%;
    scroll-behavior: smooth;
}

.logo{
    width: 300px;
    margin-top: 30px;
}

.text-yellow {
    color: #FAA51C;
}

/***Section 1***/

.__section-1{
    background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,100)),
    url('../../img/topo.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 60px 0px;
}

.__section-1-title{
    color: #FFFFFF;
    font-size: 34px;
    font-weight: 800;
    margin-top: 30px;
    line-height: 1;
}

.__section-1-title span{
    color: #fff;
}

.__section-1-text{
    color: white;
    font-size: 20px;
    font-weight: 400;
    margin-top: 20px;
}

.__section-1-text span{
    line-height: 30px;
}

.__section-1-button{
    display: block;
    padding: 20px;
    background-color: #039316;
    border-radius: 10px;
    text-decoration: none;
    text-align: center;
    line-height: 1;
    margin-top: 40px;
    transition: all 1s;
}

.bg-yellow {
    background: #FAA51C !important;
}

.__section-1-button:hover{
    transform: scale(1.1);
}

.__section-1-button span{
    font-size: 30px;
    font-weight: 800;
    color: #fff;
}

/***Section 2***/

.__section-2{
    padding: 60px 0;
}

.__intro{
    padding: 0px 0px 60px 0px;
    background: linear-gradient(rgba(0,0,0,.9), rgba(0,0,0,.1)), url('../../img/mini-intro.jpg');
    color: #ffffff;
}

.__intro .intro-text {
    padding-top: 80px;
}

.__section-2-title{
    color: #FF9F12;
    font-size: 35px;
    font-weight: 800;
    line-height: 1.2;
    letter-spacing: 1px;
    max-width: 830px;
    margin: 0 auto;
}

.__section-2-text{
    font-size: 18px;
    font-weight: 300;
    margin: 0;
}

.__section-2-text-content{
    text-align: center;
    margin-top: 100px;
}

.__section-2-text-content p{
    font-size: 18px;
    font-weight: 400;
}

/***Section 3***/

.__section-3{
    padding: 70px 0;
    background: #2F2F2F;
}

.__section-3-title{
    font-size: 40px;
    font-weight: 800;
    color: #FAA51C;
    line-height: 1;
    text-align: center;
    letter-spacing: 1px;
}

.__section-3-text{
    font-size: 19px;
    font-weight: 400;
    color: #FFFFFF;
    text-align: center;
}

.__section-3 h3 {
    color: #ffffff;
}

.__section-3-button{
    display: block;
    background-color: #04B01B;
    font-size: 25px;
    font-weight: 800;
    color: #fff;
    border-radius: 100px;
    padding: 15px 50px;
    text-align: center;
    width: 100%;
    line-height: 1;
    max-width: 480px;
    margin: 40px auto 0px auto;
    text-decoration: none;
}

.__section-2-icon-bg{
    background-color: #04b01b;
    padding: 10px;
    border-radius: 50%;
}

.__section-2-icon-bg2{
    background-color: #eb395a;
    padding: 2px;
    border-radius: 50%;
}

/***Section 4***/
.__section-4{
    padding: 80px 0;
    background-color: #fafafa;
}

.__section-4-button{
    display: block;
    padding: 20px;
    background-color: #12E177;
    border-radius: 40px;
    text-decoration: none;
    text-align: center;
    line-height: 1;
    margin-top: 20px;
    transition: all 1s;
}

.__section-4-button:hover{
    transform: scale(1.1);
}

.__section-4-button span{
    font-size: 31px;
    font-weight: 900;
    color: #000000;
    text-shadow: 0px 0px 10px rgba(0,0,0,0.3);
}

.__section-4-title{
    font-size: 40px;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 1;
    text-align: center;
    color: #2F2F2F !important;
}

.__section-4-text{
    font-size: 18px;
    font-weight: 500;
    color: #2f2f2f;
    text-align: left;
}

/***Section 5 ***/

.__section-5{
    background: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,100)),
    url('../../img/mini-intro.jpg');
    /* background-color: #000000; */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 120px 0;
}

.__section-5-text{
    font-size: 16px;
    font-weight: 400;
    color: white;
}

.__section-5-title{
    font-size: 35px;
    font-weight: 800;
    color: white;
    line-height: 40px;
    letter-spacing: 1px;
}

/***Section 6 ***/

.__section-6{
    padding: 60px 0;
}

.__section-6-text{
    font-size: 16px;
    font-weight: 500;
    color: #000;
    margin-bottom: 0px;
}

.__section-6-title{
    font-size: 35px;
    font-weight: 800;
    color: #333333;
    line-height: 1;
    letter-spacing: 1px;
    text-align:center;
}

.__section-6-subtitle{
    font-size: 24px;
    font-weight: 800;
    color: #333333;
    line-height: 1.35;
    text-transform: uppercase;
}

.__section-6-video_legend{
    text-align: center;
    color: #000000;
    font-size: 34px;
    font-weight: 800;
}

.__section-6-icon_title{
    color: #333333;
    font-size: 30px;
    font-weight: 800;
    text-transform: uppercase;
    margin-top: 6px;
}

/***Section 7***/
.__section-7{
    margin: 0 auto;
    padding: 30px 0px 60px 0px;
}

.__section-7-title{
    color: #000000;
    font-size: 30px;
    font-weight: 800;
    line-height: 1.2;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 80px;
}

.__section-7-title strong {
    color: #219110;
    padding: 10px;
    text-decoration: underline;
}

.__section-7-text{
    text-align: left;
    color: #000000;
    font-size: 18px;
    font-weight: 400;
}

/***Section 8 ***/
.__section-8{
    background: linear-gradient(rgba(0,0,0,.1), rgba(0,0,0,100)),
    url('../../img/topo.jpg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
    padding: 80px 0;
}

.__section-8-box_acess{
    background: rgba(0, 0, 0, 0.2);
    border-radius: 16px;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(5px);
    border: 2px solid white;
    padding: 3rem 1rem;
}

.__section-8-title{
    color: #FFFFFF;
    font-size: 30px;
    font-weight: 800;
    text-align: center;
    margin-bottom: 40px;
}

.__section-8-text{
    color: #FFFFFF;
    font-size: 20px;
    font-weight: 400;
    margin-bottom: 0;
}

.__section-8-button{
    display: block;
    font-size: 25px;
    line-height: 1;
    text-align: center;
    color: #fff;
    font-weight: 800;
    background-color: #04B01B;
    border-radius: 100px;
    padding: 15px 20px;
    text-decoration: none;
    margin-top: 20px;
}

/***Section 9***/
.__section-9{
    padding: 80px 0;
    background: #2F2F2F;
}

.__section-9 h3{
    color: #4F542F;
    font-size: 30px;
    font-weight: 800;
    line-height: 1;
    text-align: center;
}

.__section-9-title{
    color: #FAA51C;
    font-size: 35px;
    font-weight: 800;
    line-height: 1;
    letter-spacing: 0;
}

.__section-9-text{
    color: #ffffff;
    font-weight: 400;
}

.__section-9_collapse_trigger{
    display: block;
    width: 100%;
    padding: 14px 20px 14px 14px;
    font-size: 25px;
    line-height: 1;
    font-weight: 800;
    color: #fff;
    text-decoration: none;
    border: 1px solid #d4d4d4;
    background-color: #FAA51C;
    position: relative;
}

.collapse-content{
    border: 1px solid #d4d4d4;
    padding: 30px 20px ;
    font-weight: 400;
    font-size: 18px;
    background: white;
}

.arrow_collapse_content{
    position: absolute;
    right: 8px;
    top: 8px;
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    -webkit-transition-property: height, visibility;
    transition-property: height, visibility;
    -webkit-transition-duration: 0.35s;
    transition-duration: 0.35s;
    -webkit-transition-timing-function: ease;
    transition-timing-function: ease;
}

/***Section 10***/

.__section-10{
    background-image:url('../../img/bg-sobre.jpg');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 60px 0px;
}

.__section-10-title{
    color: #FFFFFF;
    font-size: 35px;
    font-weight: 800;
}

.__section-10-text{
    color: white;
    font-size: 14px;
    font-weight: 400;
    text-shadow: 1px 1px 2px black;
}

.__section-10-button{
    display: block;
    width: 100%;
    max-width: 420px;
    padding: 15px 20px;
    background-color: #04B01B;
    border-radius: 100px;
    text-decoration: none;
    text-align: center;
    line-height: 1;
    margin-top: 20px;
    transition: all 1s;
}

.__section-10-button:hover{
    transform: scale(1.1);
}

.__section-10-button span{
    font-size: 20px;
    font-weight: 800;
    color: #fff;
}

@media screen and (max-width:420px){

    .logo {
        margin: 0 auto !important;
        width: 150px;
    }

    .__section-1 {
        background: linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,100)),
        url('../../img/topo.jpg');
        background-position: -50% top;
        background-position: left top;
        text-align: center;
        padding: 60px 0;
    }

    h2.__section-1-title {
        font-size: 30px;
    }

    h2.__section-1-title span {
        color: #FBA715;
    }

    .__section-8-button{
        font-size: 18px;
    }

    .__section-1-button span{
        font-size: 20px;
    }

    .__section-4-title{
        margin-bottom: 40px;
    }

    .__section-6-icon_title{
        font-size: 28px;
    }

    .__section-8-title{
        font-size: 27px;
    }

    .__section-9_collapse_trigger{
        font-size: 20px;
    }

    .__section-3 {
        text-align: left !important;
    }

    .__section-5 {
        padding: 0;
        padding-top: 50px;
        padding-bottom: 50px;
    }

    .__section-3-title {
        text-align: left;
    }

    .__section-6-subtitle {
        text-align: center;
    }

    .__section-6 .container {
        text-align: center;
    }

    .__section-3 .container {
        text-align: left !important;
    }

    .__section-6-text {
        text-align: center;
    }

    .__section-4 {
        text-align: left !important;
    }

    .__section-4-text {
        margin: 0 !important;
        padding: 0 !important;
        padding-bottom: 40px !important;
        text-align: center;
    }

    .img-burguer-7d {
        margin-top: 80px !important;
        margin-bottom: 80px !important;
    }

}

@media screen and (min-width: 600px) and (max-width: 700px){

    .logo {
        width: 150px;
    }

    .__section-1 {
        background-size: auto;
        background-position: left top;
    }

}

@media screen and (min-width: 300px) and (max-width: 400px){

    h2.__section-1-title {
        font-size: 25px;
    }

}

hr.divider {
    border: 0;
    height: 0; /* Firefox... */
    box-shadow: 0 0 10px 1px #FAA41C;
}
hr.divider:after {  /* Not really supposed to work, but does */
    content: "\00a0";  /* Prevent margin collapse */
}

.__section-16 {
    margin-top: 80px;
    margin-bottom: 80px;
}

.__section-16 h1 {
    font-weight: bold;
}

.__section-4-text {
    margin-top: 0px;
    padding: 1rem;
}

strong.title {
    font-weight: bold;
    font-size: 28px;
    line-height: 10px;
}

span.old-offer {
    color: red;
    font-size: 29px !important;
    font-weight: bold;
    text-align: center;
}

strong.offer {
    font-size: 42px;
}

.offer-container {
    margin: 0 auto;
    margin-top: 40px;
    text-align: center !important;
}

.offer-container .text-white {
    font-size: 22px;
}

section.testimonials img {
    width: 500px;
}

span.bonus-old-offer {
    color: red;
    font-weight: bold;
    text-decoration: line-through;
}

span.bonus-actual-offer {
    font-size: 22px;
    font-weight: bold;
    background: #FAA51C;
    margin: 10px;
    padding: 10px;
}

.title-red {
    color: #FE4365 !important;
}

.title-green {
    color: #02B01B !important;
}

.course-module {
    padding: 2rem 1rem;
    text-align: center;
    background: rgba(250, 165, 28, 0.64);
    border-radius: 5px;
    height: 100%;
}

.__section-7 .row:not(:last-child) {
    padding-bottom: 100px;
    border-bottom: 1px dotted black;
}


ul.offer-check-list {
    padding-bottom: 10px !important;
}

h1.final-price {
    color: green;
    font-size: 52px !important;
    font-weight: bold;
}

.video-thumbnail {
    position: relative;
}

.icon-wrap {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
}


.video-container {
    position: relative;
    cursor: pointer;
    margin-top: 40px;
    margin-bottom: 100px !important;
}

.image {
    opacity: 1;
    display: block;
    width: 100% !important;
    float: left;
    height: auto;
    transition: .5s ease;
    backface-visibility: hidden;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    color: #ffffff;
    font-weight: bold;
    font-size: 32px;
}

.overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: .5s ease;
    background-color: #008CBA;
}

.container:hover .overlay {
    opacity: 1;
}

.text {
    background-color: #04AA6D;
    color: white;
    font-size: 16px;
    padding: 16px 32px;
}

.middle {
    transition: .5s ease;
    opacity: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.container:hover .image {
    opacity: 0.3;
}

.container:hover .middle {
    opacity: 1;
}

.text {
    background-color: #04AA6D;
    color: white;
    font-size: 16px;
    padding: 16px 32px;
}

a#desconto {
    transition: all 1s;
}

a#desconto:hover{
    transform: scale(1.075);
}

.img_effect{
    animation-name: up_down;
    animation-duration: 3s;
    animation-iteration-count: infinite;
}

@keyframes up_down {
    0%{
        transform: translateY(0px);
    }

    50%{
        transform: translateY(-10px);
    }

    100%{
        transform: translateY(0px);
    }
}

@media screen and (max-width:992px){
    .__section-2{
        padding: 0px 0px 60px 0px;
    }

    .__section-1-button span{
        font-size: 27px;
    }
}

@media screen and (max-width:768px){
    .__intro{
        padding: 0px 0px 60px 0px;
    }

    .__intro img{
        translate: -50px;
    }

    .__section-3-title{
        font-size: 30px;
    }
}

@media screen and (max-width:450px){
    .__section-1-button{
        padding: 10px;
    }
    .__section-2-title{
        font-size: 25px;
    }
    .__section-3-title{
        font-size: 28px;
    }
    .__section-4-title{
        font-size: 28px;
    }
}
